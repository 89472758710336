import Vue from 'vue';
import App from './App.vue';

Vue.config.productionTip = false;
const instance = new Vue({
  render: (h) => h(App),
});
window.instanceObj = instance;

instance.$mount('#app');
