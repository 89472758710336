<template>
  <div id="app">
    <div class="content">
      <img class="logo" src="/settings/logo.png">
      <img class="in-build" src="img/build.png">
    </div>
    <div class="footer">
      <div class="item">Copyright©2022-{{new Date().getFullYear()}} 灯翼智能科技（苏州）有限公司 版权所有</div>
      <div class="item"><a target="_blank" href="https://beian.miit.gov.cn/">苏ICP备2022038080号</a></div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'app',
};
</script>
<style lang="scss">
  @include init-base;
  html {
    overflow: overlay;
  }
  body {
    overflow: visible !important;
    background-color: #E3E3E3;
  }
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    min-height: 100%;
    a {
      text-decoration: none;
    }
  }
  .content {
    position: relative;
    min-height: calc(100vh - 104px);
    padding-top: 10vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      position: absolute;
      top: 20px;
      left: 30px;
      height: 60px;
    }
    .in-build {
      max-width: 80%;
    }
  }
  .footer {
    text-align: center;
    padding: 30px;
    font-size: $font-size-0;
    color: $text-secondary;
    line-height: 22px;
    a {
      &,
      &:visited,
      &:active,
      &:focus {
        color: $text-secondary;
      }
    }
  }
</style>
